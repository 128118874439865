.advancedscroller {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .advancedscroller * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .advancedscroller > .items {
    display: none; }

/* -- functional styling */
.advancedscroller-con {
  position: relative;
  width: 100%; }

.advancedscroller {
  position: relative;
  width: 100%;
  min-height: 50px;
  margin-bottom: 10px;
  /* ==== hide the images until loaded */ }
  .advancedscroller .item-tobe {
    display: none;
    -webkit-backface-visibility: hidden;
    position: relative; }
  .advancedscroller .item-tobe .feed-description, .advancedscroller .item .feed-description {
    display: none; }
  .advancedscroller .item .description-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 400px; }
    .advancedscroller .item .description-wrapper .description-wrapper--icon-con {
      width: 40px;
      height: 40px;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
      transition-property: background;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
      -moz-transition-property: background;
      -moz-transition-duration: 0.3s;
      -moz-transition-timing-function: ease-out;
      -webkit-transition-property: background;
      -webkit-transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      -o-transition-property: background;
      -o-transition-duration: 0.3s;
      -o-transition-timing-function: ease-out;
      -ms-transition-property: background;
      -ms-transition-duration: 0.3s;
      -ms-transition-timing-function: ease-out; }
      .advancedscroller .item .description-wrapper .description-wrapper--icon-con > i {
        font-size: 18px;
        color: #FFFFFF;
        font-family: "FontAwesome", arial, serif;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        transition-property: all;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
        -moz-transition-property: all;
        -moz-transition-duration: 0.3s;
        -moz-transition-timing-function: ease-out;
        -webkit-transition-property: all;
        -webkit-transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        -o-transition-property: all;
        -o-transition-duration: 0.3s;
        -o-transition-timing-function: ease-out;
        -ms-transition-property: all;
        -ms-transition-duration: 0.3s;
        -ms-transition-timing-function: ease-out; }
      .advancedscroller .item .description-wrapper .description-wrapper--icon-con > i.fa-times {
        opacity: 0;
        font-size: 18px; }
    .advancedscroller .item .description-wrapper .description-wrapper--text {
      white-space: normal;
      background-color: #FFFFFF;
      padding: 30px;
      line-height: 1.8;
      position: absolute;
      right: 40px;
      bottom: 0;
      width: calc(100% - 40px);
      opacity: 0;
      visibility: hidden;
      transition-property: all;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
      -moz-transition-property: all;
      -moz-transition-duration: 0.3s;
      -moz-transition-timing-function: ease-out;
      -webkit-transition-property: all;
      -webkit-transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      -o-transition-property: all;
      -o-transition-duration: 0.3s;
      -o-transition-timing-function: ease-out;
      -ms-transition-property: all;
      -ms-transition-duration: 0.3s;
      -ms-transition-timing-function: ease-out; }
      .advancedscroller .item .description-wrapper .description-wrapper--text h4 {
        font-family: "Lato",arial, serif;
        font-size: 25px;
        color: #212121;
        margin: 7px 0 17px;
        font-weight: 900;
        line-height: 1; }
      .advancedscroller .item .description-wrapper .description-wrapper--text p {
        line-height: 1.92;
        margin-bottom: 2px;
        color: #444444; }
  .advancedscroller .item .description-wrapper:hover .description-wrapper--icon-con {
    background-color: #e74c3c; }
  .advancedscroller .item .description-wrapper.active .description-wrapper--text {
    opacity: 1;
    visibility: visible; }
  .advancedscroller .item .description-wrapper.active .description-wrapper--icon-con {
    background-color: #e74c3c; }
  .advancedscroller .item .description-wrapper.active i.fa-info {
    opacity: 0;
    filter: blur(10px);
    -webkit-filter: blur(10px); }
  .advancedscroller .item .description-wrapper.active i.fa-times {
    opacity: 1;
    filter: blur(0px);
    -webkit-filter: blur(0px); }
  .advancedscroller .loaded .item {
    position: relative; }
  .advancedscroller ul {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0; }
  .advancedscroller ul > li {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0; }
  .advancedscroller ul:after {
    content: '\0020';
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
    clear: both; }
  .advancedscroller .thumbsCon {
    display: block;
    overflow: hidden;
    position: relative;
    height: auto;
    transition-property: height;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    -moz-transition-property: height;
    -moz-transition-duration: 0.3s;
    -moz-transition-timing-function: ease-out;
    -webkit-transition-property: height;
    -webkit-transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    -o-transition-property: height;
    -o-transition-duration: 0.3s;
    -o-transition-timing-function: ease-out;
    -ms-transition-property: height;
    -ms-transition-duration: 0.3s;
    -ms-transition-timing-function: ease-out; }
  .advancedscroller .bulletsCon {
    display: block;
    position: relative;
    z-index: 4; }
  .advancedscroller .bulletsCon:after {
    display: block;
    content: " ";
    clear: both; }
  .advancedscroller .thumbsClip {
    position: relative;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transition-property: left;
    transition-duration: 0.9s;
    transition-timing-function: ease-out;
    -moz-transition-property: left;
    -moz-transition-duration: 0.9s;
    -moz-transition-timing-function: ease-out;
    -webkit-transition-property: left;
    -webkit-transition-duration: 0.9s;
    -webkit-transition-timing-function: ease-out;
    -o-transition-property: left;
    -o-transition-duration: 0.9s;
    -o-transition-timing-function: ease-out;
    -ms-transition-property: left;
    -ms-transition-duration: 0.9s;
    -ms-transition-timing-function: ease-out;
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    /* older webkit */
    -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.1);
    -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.1);
    -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.1);
    -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.1);
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.1);
    /* easeOutBack */ }
  .advancedscroller .thumbsClip > * {
    display: inline-block;
    float: none;
    vertical-align: top; }
  .advancedscroller .the-thumb-con {
    position: relative; }
  .advancedscroller .item .the-thumb {
    width: 100%;
    height: 100%;
    background-size: cover; }
  .advancedscroller .item p:empty {
    display: none; }
  .advancedscroller .item.needs-loading:not(.loaded) {
    height: 10px; }

.advancedscroller.with-shadow {
  margin-bottom: 35px; }

.advancedscroller.item-padding-30 .thumbsClip > .item {
  padding-left: 15px;
  padding-right: 15px; }

.advancedscroller.with-shadow:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 33px;
  background-image: url(img/shadow.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top; }

.advancedscroller.no-need-for-nav .bulletsCon {
  display: none; }
.advancedscroller.no-need-for-nav .arrowsCon {
  display: none; }

.advancedscroller:after {
  content: '\0020';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
  clear: both; }

.advancedscroller.from-zoomfolio {
  overflow: visible; }
  .advancedscroller.from-zoomfolio .items {
    display: none; }

img.fullwidth {
  width: 100%;
  display: block; }

/* -- ----------- --------
--- estethic styling
*/
@-webkit-keyframes preloader-semicircles-tween {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg); }
  50% {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg); }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg); } }
@keyframes preloader-semicircles-tween {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg); }
  50% {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg); }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg); } }
.advancedscroller .preloader, .advancedscroller .preloader-squares1 {
  width: 128px;
  height: 40px;
  background: transparent url(img/preloader.gif) center center no-repeat;
  margin-left: -64px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  left: 50%; }
.advancedscroller .preloader-semicircles {
  width: 30px;
  height: 30px;
  background: rgba(230, 230, 230, 0.9);
  border-radius: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-right: -15px;
  animation: preloader-semicircles-tween 1.4s infinite linear;
  -webkit-animation: preloader-semicircles-tween 1.4s infinite linear; }
.advancedscroller .preloader-semicircles:after {
  position: absolute;
  width: 50px;
  height: 50px;
  border-top: 10px solid rgba(200, 200, 200, 0.5);
  border-bottom: 10px solid rgba(200, 200, 200, 0.5);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-radius: 50px;
  content: '';
  top: -20px;
  left: -20px;
  box-sizing: content-box; }
.advancedscroller .bulletsCon {
  height: 20px;
  text-align: center;
  margin: 15px auto;
  cursor: pointer; }
.advancedscroller .thumbsClip > * {
  padding: 0px 10px;
  transition-property: border, padding, opacity, visibility;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  -moz-transition-property: border, padding, opacity, visibility;
  -moz-transition-duration: 0.5s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: border, padding, opacity, visibility;
  -webkit-transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: border, padding, opacity, visibility;
  -o-transition-duration: 0.5s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: border, padding, opacity, visibility;
  -ms-transition-duration: 0.5s;
  -ms-transition-timing-function: ease-out; }
.advancedscroller .thumbsClip > *.last-in-row {
  border-right: 1px solid transparent; }
.advancedscroller .thumbsClip > *:last-child {
  border-right: 1px solid transparent; }
.advancedscroller .arrowsCon > .arrow-left, .advancedscroller .arrowsCon > .arrow-right {
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-image: url("img/arrow-left.png");
  width: 30px;
  height: 30px;
  position: absolute;
  left: 5px;
  top: 45%;
  margin-top: -15px;
  cursor: pointer;
  opacity: 0.69;
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -moz-transition-property: opacity;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: opacity;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: opacity;
  -o-transition-duration: 0.3s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: opacity;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out; }
.advancedscroller .arrowsCon > .arrow-right {
  background-image: url("img/arrow-right.png");
  left: auto;
  right: 5px;
  top: 45%; }
.advancedscroller .arrowsCon > .arrow-left:hover, .advancedscroller .arrowsCon > .arrow-right:hover {
  opacity: 1; }
.advancedscroller .bulletsCon span.bullet {
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-position: 0 0%;
  background-color: transparent;
  background-image: url(img/bullet.png);
  display: inline-block;
  margin-right: 3px;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -moz-transition-property: background;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: background;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: background;
  -o-transition-duration: 0.3s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: background;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out; }
.advancedscroller .bulletsCon span.bullet:hover {
  background-position: 0 100%; }
.advancedscroller .bulletsCon span.bullet.active {
  background-position: 0 100%; }
.advancedscroller .name {
  text-align: center;
  margin-top: 10px; }
.advancedscroller .price {
  text-align: center;
  margin-top: 5px;
  font-weight: bold; }
.advancedscroller .price > span {
  display: inline-block; }
.advancedscroller .addtocart-con {
  text-align: center;
  margin-top: 10px; }
.advancedscroller .addtocart-con > .button-addtocart {
  margin-top: 0px; }

.advancedscroller.transition-fade .thumbsClip > * {
  opacity: 0;
  visibility: hidden; }

.advancedscroller.transition-testimonials_transition_1 .testimonial-col-name {
  position: relative;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -moz-transition-property: all;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: all;
  -o-transition-duration: 0.3s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: all;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out;
  transform: translate(0, 50%); }
.advancedscroller.transition-testimonials_transition_1 .testimonial-col-quote {
  position: relative;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -moz-transition-property: all;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: all;
  -o-transition-duration: 0.3s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: all;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out;
  transform: translate(50%, 0); }
.advancedscroller.transition-testimonials_transition_1 .thumbsClip > * {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden; }

.testimonial-slide-con {
  padding: 100px 30px; }

.advancedscroller.transition-testimonials_transition_1 .thumbsClip > *.currItem {
  opacity: 1;
  visibility: visible; }
  .advancedscroller.transition-testimonials_transition_1 .thumbsClip > *.currItem .testimonial-col-name, .advancedscroller.transition-testimonials_transition_1 .thumbsClip > *.currItem .testimonial-col-quote {
    transform: translate(0%, 0); }

.advancedscroller.transition-wipeoutandfade .thumbsClip {
  transition-property: none;
  transition-duration: 0s;
  transition-timing-function: linear;
  -moz-transition-property: none;
  -moz-transition-duration: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-property: none;
  -webkit-transition-duration: 0s;
  -webkit-transition-timing-function: linear;
  -o-transition-property: none;
  -o-transition-duration: 0s;
  -o-transition-timing-function: linear;
  -ms-transition-property: none;
  -ms-transition-duration: 0s;
  -ms-transition-timing-function: linear;
  white-space: nowrap; }
  .advancedscroller.transition-wipeoutandfade .thumbsClip > * {
    opacity: 0;
    visibility: hidden;
    width: 0;
    position: relative; }
    .advancedscroller.transition-wipeoutandfade .thumbsClip > * .wipeout-wrapper {
      overflow: hidden;
      position: relative;
      width: 100%;
      top: 0;
      left: 0; }
      .advancedscroller.transition-wipeoutandfade .thumbsClip > * .wipeout-wrapper .wipeout-wrapper-inner {
        position: relative; }
    .advancedscroller.transition-wipeoutandfade .thumbsClip > * .wipeout-wrapper {
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
      .advancedscroller.transition-wipeoutandfade .thumbsClip > * .wipeout-wrapper .wipeout-wrapper-inner {
        position: relative;
        width: 100%;
        height: 100%; }
        .advancedscroller.transition-wipeoutandfade .thumbsClip > * .wipeout-wrapper .wipeout-wrapper-inner > .vplayer {
          width: 100% !important;
          height: 100%; }

.advancedscroller.transition-fade .thumbsClip > *.currItem, .advancedscroller.transition-wipeoutandfade .thumbsClip > *.currItem, .advancedscroller.transition-testimonials_transition_1 .thumbsClip > *.currItem {
  opacity: 1;
  visibility: visible; }

.advancedscroller.swipe-enabled {
  cursor: move;
  cursor: -moz-grab;
  cursor: grab;
  cursor: url(img/openhand.cur) 4 4, move; }

.advancedscroller.swipe-enabled.closedhand {
  cursor: url(img/closedhand.cur) 4 4, move; }
  .advancedscroller.swipe-enabled.closedhand .thumbsClip {
    transition-property: left;
    transition-duration: 0s;
    transition-timing-function: linear;
    -moz-transition-property: left;
    -moz-transition-duration: 0s;
    -moz-transition-timing-function: linear;
    -webkit-transition-property: left;
    -webkit-transition-duration: 0s;
    -webkit-transition-timing-function: linear;
    -o-transition-property: left;
    -o-transition-duration: 0s;
    -o-transition-timing-function: linear;
    -ms-transition-property: left;
    -ms-transition-duration: 0s;
    -ms-transition-timing-function: linear; }

.advancedscroller.center-v .thumbsCon {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%); }

.advancedscroller.mode-onlyoneitem .thumbsCon .thumbsClip > li {
  width: 100%;
  border-right: 0; }
.advancedscroller.mode-onlyoneitem .vplayer {
  position: absolute;
  width: 100%;
  height: 100%; }

.advancedscroller.islastpage .thumbsClip > *.last-in-row {
  border-right: 1px solid rgba(0, 0, 0, 0.3); }
.advancedscroller.islastpage .thumbsClip > *:last-child {
  border-right: 1px solid transparent; }

div.button-addtocart a {
  color: #fff; }

.button-addtocart {
  font-family: Helvetica, Arial, sans-serif;
  background: rgba(0, 0, 0, 0.5);
  padding: 8px 15px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  margin: 10px 0;
  padding: 4px 10px;
  text-decoration: none;
  border-radius: 3px;
  color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: #ba2b00;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2JhMmIwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjFhMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #ba2b00 0%, #ff1a00 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ba2b00), color-stop(100%, #ff1a00));
  background: -webkit-linear-gradient(top, #ba2b00 0%, #ff1a00 100%);
  background: -o-linear-gradient(top, #ba2b00 0%, #ff1a00 100%);
  background: -ms-linear-gradient(top, #ba2b00 0%, #ff1a00 100%);
  background: linear-gradient(to bottom, #ba2b00 0%, #ff1a00 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ba2b00', endColorstr='#ff1a00',GradientType=0 );
  font-family: 'Lucida Grande', 'Lucida Sans Unicode', sans-serif;
  font-size: 9px; }

.old-price {
  margin-right: 5px;
  color: red;
  text-decoration: line-through; }

.advancedscroller.mode-onlyoneitem .thumbsClip.center-it {
  position: absolute;
  margin-left: -50%; }
.advancedscroller.mode-onlyoneitem .thumbsClip > * {
  padding: 0px; }
.advancedscroller.mode-onlyoneitem .thumbsClip li > img {
  display: block; }
.advancedscroller.mode-onlyoneitem .thumbsClip li > a > img {
  display: block; }

.advancedscroller.skin-default .thumbsClip > * {
  border-right: 1px solid rgba(0, 0, 0, 0.3); }

.advancedscroller.skin-white {
  margin-top: 10px; }
  .advancedscroller.skin-white a {
    color: inherit;
    text-decoration: none; }
  .advancedscroller.skin-white a:hover {
    opacity: 0.7; }
  .advancedscroller.skin-white .thumbsClip > * {
    padding: 10px;
    border-right: 0; }
  .advancedscroller.skin-white .thumbsClip > *.last-in-row {
    padding: 10px;
    border-right: 0; }
  .advancedscroller.skin-white .arrowsCon > .arrow-left, .advancedscroller.skin-white .arrowsCon > .arrow-right {
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url("img/arrow-left-white.png");
    width: 30px;
    height: 30px;
    position: absolute;
    left: auto;
    right: 65px;
    top: -15px;
    margin-top: -15px;
    cursor: pointer;
    opacity: 0.69;
    z-index: 4;
    transition-property: left;
    transition-duration: 0s;
    transition-timing-function: linear;
    -moz-transition-property: left;
    -moz-transition-duration: 0s;
    -moz-transition-timing-function: linear;
    -webkit-transition-property: left;
    -webkit-transition-duration: 0s;
    -webkit-transition-timing-function: linear;
    -o-transition-property: left;
    -o-transition-duration: 0s;
    -o-transition-timing-function: linear;
    -ms-transition-property: left;
    -ms-transition-duration: 0s;
    -ms-transition-timing-function: linear; }
  .advancedscroller.skin-white .arrowsCon > .arrow-right {
    background-image: url("img/arrow-right-white.png");
    left: auto;
    right: 45px;
    top: -15px; }
  .advancedscroller.skin-white .arrowsCon > .arrow-left:hover, .advancedscroller.skin-white .arrowsCon > .arrow-right:hover {
    opacity: 1; }

.advancedscroller.skin-black {
  margin-top: 10px; }
  .advancedscroller.skin-black a {
    color: inherit;
    text-decoration: none; }
  .advancedscroller.skin-black a:hover {
    opacity: 0.7; }
  .advancedscroller.skin-black .thumbsClip > * {
    padding: 10px;
    border-right: 0; }
  .advancedscroller.skin-black .thumbsClip > *.first-in-row {
    padding: 10px;
    padding-left: 0px;
    border-right: 0; }
  .advancedscroller.skin-black .thumbsClip > *.last-in-row {
    padding: 10px;
    padding-right: 0px;
    border-right: 0; }
  .advancedscroller.skin-black .arrowsCon > .arrow-left, .advancedscroller.skin-black .arrowsCon > .arrow-right {
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url("img/arrow-left-black-small.png");
    width: 30px;
    height: 30px;
    position: absolute;
    left: auto;
    right: 20px;
    top: -15px;
    margin-top: -15px;
    cursor: pointer;
    opacity: 0.69;
    z-index: 4;
    transition-property: left;
    transition-duration: 0s;
    transition-timing-function: linear;
    -moz-transition-property: left;
    -moz-transition-duration: 0s;
    -moz-transition-timing-function: linear;
    -webkit-transition-property: left;
    -webkit-transition-duration: 0s;
    -webkit-transition-timing-function: linear;
    -o-transition-property: left;
    -o-transition-duration: 0s;
    -o-transition-timing-function: linear;
    -ms-transition-property: left;
    -ms-transition-duration: 0s;
    -ms-transition-timing-function: linear; }
  .advancedscroller.skin-black .arrowsCon > .arrow-right {
    background-image: url("img/arrow-right-black-small.png");
    left: auto;
    right: 0px;
    top: -15px; }
  .advancedscroller.skin-black .arrowsCon > .arrow-left:hover, .advancedscroller.skin-black .arrowsCon > .arrow-right:hover {
    opacity: 1; }

.advancedscroller.skin-inset .thumbsClip > * {
  padding: 0px; }
.advancedscroller.skin-inset .arrowsCon > .arrow-left, .advancedscroller.skin-inset .arrowsCon > .arrow-right {
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-image: url("img/arrow-left-black.png");
  width: 40px;
  height: 40px;
  position: absolute;
  left: 5px;
  top: 45%;
  margin-top: -20px;
  cursor: pointer;
  opacity: 0.69;
  z-index: 4;
  transition-property: left;
  transition-duration: 0s;
  transition-timing-function: linear;
  -moz-transition-property: left;
  -moz-transition-duration: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-property: left;
  -webkit-transition-duration: 0s;
  -webkit-transition-timing-function: linear;
  -o-transition-property: left;
  -o-transition-duration: 0s;
  -o-transition-timing-function: linear;
  -ms-transition-property: left;
  -ms-transition-duration: 0s;
  -ms-transition-timing-function: linear; }
.advancedscroller.skin-inset .arrowsCon > .arrow-right {
  background-image: url("img/arrow-right-black.png");
  left: auto;
  right: 5px;
  top: 45%; }
.advancedscroller.skin-inset .arrowsCon > .arrow-left:hover, .advancedscroller.skin-inset .arrowsCon > .arrow-right:hover {
  opacity: 1; }
.advancedscroller.skin-inset .transition-fade .thumbsClip > * {
  opacity: 0;
  visibility: hidden; }
.advancedscroller.skin-inset .transition-fade .thumbsClip > *.currItem {
  opacity: 1;
  visibility: visible; }

.advancedscroller.skin-karma-inset .thumbsClip > * {
  padding: 0px; }
.advancedscroller.skin-karma-inset .arrowsCon > .arrow-left, .advancedscroller.skin-karma-inset .arrowsCon > .arrow-right {
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.2);
  background-image: none;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -20px;
  cursor: pointer;
  z-index: 4;
  transition-property: left;
  transition-duration: 0s;
  transition-timing-function: linear;
  -moz-transition-property: left;
  -moz-transition-duration: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-property: left;
  -webkit-transition-duration: 0s;
  -webkit-transition-timing-function: linear;
  -o-transition-property: left;
  -o-transition-duration: 0s;
  -o-transition-timing-function: linear;
  -ms-transition-property: left;
  -ms-transition-duration: 0s;
  -ms-transition-timing-function: linear; }
.advancedscroller.skin-karma-inset .arrowsCon > .arrow-left:before, .advancedscroller.skin-karma-inset .arrowsCon > .arrow-right:before {
  position: absolute;
  content: "\f104";
  font-family: FontAwesome, arial;
  font-size: 34px;
  top: 50%;
  left: 50%;
  color: #FFFFFF;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%); }
.advancedscroller.skin-karma-inset .arrowsCon > .arrow-right:before {
  content: "\f105"; }
.advancedscroller.skin-karma-inset .arrowsCon > .arrow-right {
  left: auto;
  right: 0px;
  top: 50%; }
.advancedscroller.skin-karma-inset .arrowsCon > .arrow-left:hover, .advancedscroller.skin-karma-inset .arrowsCon > .arrow-right:hover {
  background-color: #e74c3c; }
.advancedscroller.skin-karma-inset .transition-fade .thumbsClip > * {
  opacity: 0;
  visibility: hidden; }
.advancedscroller.skin-karma-inset .transition-fade .thumbsClip > *.currItem {
  opacity: 1;
  visibility: visible; }

.advancedscroller.skin-regen .thumbsClip > * {
  padding: 0px; }
.advancedscroller.skin-regen .arrowsCon > .arrow-left, .advancedscroller.skin-regen .arrowsCon > .arrow-right {
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-image: url("img/arrow-round-left.png");
  width: 50px;
  height: 50px;
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -25px;
  cursor: pointer;
  opacity: 0.69;
  background-size: cover;
  z-index: 4;
  transition-property: left;
  transition-duration: 0s;
  transition-timing-function: linear;
  -moz-transition-property: left;
  -moz-transition-duration: 0s;
  -moz-transition-timing-function: linear;
  -webkit-transition-property: left;
  -webkit-transition-duration: 0s;
  -webkit-transition-timing-function: linear;
  -o-transition-property: left;
  -o-transition-duration: 0s;
  -o-transition-timing-function: linear;
  -ms-transition-property: left;
  -ms-transition-duration: 0s;
  -ms-transition-timing-function: linear; }
.advancedscroller.skin-regen .arrowsCon > .arrow-right {
  background-image: url("img/arrow-round-right.png");
  left: auto;
  right: 15px;
  top: 50%; }
.advancedscroller.skin-regen .arrowsCon > .arrow-left:hover, .advancedscroller.skin-regen .arrowsCon > .arrow-right:hover {
  opacity: 1; }
.advancedscroller.skin-regen .bulletsCon {
  display: block;
  position: absolute;
  width: 100%;
  top: auto;
  bottom: 10px; }
.advancedscroller.skin-regen .bulletsCon span.bullet {
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-image: none;
  display: inline-block;
  margin-right: 8px;
  background-color: rgba(0, 0, 0, 0.4);
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -moz-transition-property: background;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: background;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: background;
  -o-transition-duration: 0.3s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: background;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out; }
.advancedscroller.skin-regen .bulletsCon span.bullet.active {
  background-color: rgba(0, 0, 0, 0.7);
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px; }

.advancedscroller.skin-agata .arrowsCon > .arrow-left, .advancedscroller.skin-agata .arrowsCon > .arrow-right {
  position: absolute;
  left: 5px;
  top: 45%; }
.advancedscroller.skin-agata .arrowsCon > .arrow-right {
  left: auto;
  right: 5px; }

.advancedscroller.skin-agata .arrowsCon > .arrow-left, .advancedscroller.skin-agata .arrowsCon > .arrow-right, .arrow-left.skin-agata, .arrow-right.skin-agata {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: none;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  border-radius: 3px;
  cursor: pointer;
  background-color: #383838;
  opacity: 1;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -moz-transition-property: background-color;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: background-color;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: background-color;
  -o-transition-duration: 0.3s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: background-color;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out; }

.advancedscroller.skin-agata .arrowsCon > .arrow-left:before, .arrow-left.skin-agata:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px inset transparent;
  border-bottom: 6px solid transparent;
  border-right: 8px solid #e3e3e3;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -5px;
  margin-top: -6px; }

.advancedscroller.skin-agata .arrowsCon > .arrow-right:before, .arrow-right.skin-agata:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px inset transparent;
  border-bottom: 6px solid transparent;
  border-left: 8px solid #e3e3e3;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -3px;
  margin-top: -6px; }

.advancedscroller.skin-agata .arrowsCon > .arrow-left:hover, .arrow-left.skin-agata:hover, .advancedscroller.skin-agata .arrowsCon > .arrow-right:hover, .arrow-right.skin-agata:hover {
  background-color: #00a99d; }

/* -- END skin-agata */
/* -- skin-agata-inset */
.advancedscroller.skin-agata-inset .thumbsClip > * {
  padding: 0px; }
.advancedscroller.skin-agata-inset > .arrowsCon > .arrow-left, .advancedscroller.skin-agata-inset .arrowsCon > .arrow-right {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("img/skin-agapa-arrow-left.png");
  width: 40px;
  height: 60px;
  position: absolute;
  left: 0px;
  top: 45%;
  margin-top: -30px;
  cursor: pointer;
  background-color: rgba(33, 33, 33, 0.3);
  z-index: 4;
  border-radius: 0 5px 5px 0;
  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -moz-transition-property: background-color;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: background-color;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: background-color;
  -o-transition-duration: 0.3s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: background-color;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out; }
.advancedscroller.skin-agata-inset > .arrowsCon > .arrow-right {
  background-image: url("img/skin-agapa-arrow-right.png");
  left: auto;
  right: 0px;
  top: 45%;
  border-radius: 5px 0 0 5px; }
.advancedscroller.skin-agata-inset > .arrowsCon > .arrow-left:hover, .advancedscroller.skin-agata-inset .arrowsCon > .arrow-right:hover {
  background-color: rgba(33, 33, 33, 0.7); }
.advancedscroller.skin-agata-inset .transition-fade .thumbsClip > * {
  opacity: 0;
  visibility: hidden; }
.advancedscroller.skin-agata-inset .transition-fade .thumbsClip > *.currItem {
  opacity: 1;
  visibility: visible; }
.advancedscroller.skin-agata-inset .bulletsCon {
  position: absolute;
  width: 100%;
  bottom: 10px;
  margin: 0;
  text-align: center; }
  .advancedscroller.skin-agata-inset .bulletsCon .bullet {
    width: 20px;
    height: 4px;
    border-radius: 3px;
    background-color: #4d4d4d;
    background-image: none; }
  .advancedscroller.skin-agata-inset .bulletsCon .bullet.active {
    background-color: #00a99d; }

/* -- skin-avanti-inset */
.advancedscroller.skin-avanti-inset .thumbsClip > *, .advancedscroller.skin-bubble-inset .thumbsClip > * {
  padding: 0px; }
.advancedscroller.skin-avanti-inset > .arrowsCon > .arrow-left, .advancedscroller.skin-avanti-inset .arrowsCon > .arrow-right, .advancedscroller.skin-bubble-inset > .arrowsCon > .arrow-left, .advancedscroller.skin-bubble-inset .arrowsCon > .arrow-right {
  background-image: none;
  left: 15px;
  top: 45%;
  margin-top: -15px;
  opacity: 1; }
.advancedscroller.skin-avanti-inset > .arrowsCon > .arrow-right, .advancedscroller.skin-bubble-inset > .arrowsCon > .arrow-right {
  background-image: none;
  left: auto;
  right: 15px;
  top: 45%;
  margin-top: -15px;
  width: auto; }
.advancedscroller.skin-avanti-inset > .arrowsCon > .arrow-left path, .advancedscroller.skin-avanti-inset .arrowsCon > .arrow-right path, .advancedscroller.skin-bubble-inset > .arrowsCon > .arrow-left path, .advancedscroller.skin-bubble-inset .arrowsCon > .arrow-right path {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -moz-transition-property: all;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: all;
  -o-transition-duration: 0.3s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: all;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out; }
.advancedscroller.skin-avanti-inset > .arrowsCon > .arrow-left:hover #Layer_1 path, .advancedscroller.skin-avanti-inset .arrowsCon > .arrow-right:hover path, .advancedscroller.skin-bubble-inset > .arrowsCon > .arrow-left:hover #Layer_1 path, .advancedscroller.skin-bubble-inset .arrowsCon > .arrow-right:hover path {
  fill: #00a99d; }
.advancedscroller.skin-avanti-inset .transition-fade .thumbsClip > *, .advancedscroller.skin-bubble-inset .transition-fade .thumbsClip > * {
  opacity: 0;
  visibility: hidden; }
.advancedscroller.skin-avanti-inset .transition-fade .thumbsClip > *.currItem, .advancedscroller.skin-bubble-inset .transition-fade .thumbsClip > *.currItem {
  opacity: 1;
  visibility: visible; }
.advancedscroller.skin-avanti-inset .bulletsCon, .advancedscroller.skin-bubble-inset .bulletsCon {
  position: absolute;
  width: 100%;
  bottom: 10px;
  margin: 0;
  text-align: center; }
  .advancedscroller.skin-avanti-inset .bulletsCon .bullet, .advancedscroller.skin-bubble-inset .bulletsCon .bullet {
    width: 20px;
    height: 4px;
    border-radius: 3px;
    background-color: #4d4d4d;
    background-image: none; }
  .advancedscroller.skin-avanti-inset .bulletsCon .bullet.active, .advancedscroller.skin-bubble-inset .bulletsCon .bullet.active {
    background-color: #00a99d; }

/* -- END skin-avanti-inset */
/* -- skin-qcre-inset */
.advancedscroller.skin-qcre .thumbsClip > * {
  padding: 0px; }
.advancedscroller.skin-qcre > .arrowsCon > .arrow-left, .advancedscroller.skin-qcre .arrowsCon > .arrow-right {
  background-image: none;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  margin-top: -0px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 30px;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -moz-transition-property: background;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: background;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: background;
  -o-transition-duration: 0.3s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: background;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out; }
.advancedscroller.skin-qcre > .arrowsCon > .arrow-left:before, .advancedscroller.skin-qcre .arrowsCon > .arrow-right:before {
  content: "\f105";
  font-family: FontAwesome,sans-sarif;
  font-size: 30px;
  color: #fff;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -15px;
  margin-left: -5px;
  line-height: 1; }
.advancedscroller.skin-qcre > .arrowsCon > .arrow-left:before {
  content: "\f104";
  margin-left: -5px; }
.advancedscroller.skin-qcre > .arrowsCon > .arrow-left {
  background-image: none;
  left: auto;
  right: 30px;
  top: auto;
  bottom: 0;
  margin-top: -0px; }
.advancedscroller.skin-qcre > .arrowsCon > .arrow-left:hover, .advancedscroller.skin-qcre .arrowsCon > .arrow-right:hover {
  background-color: #e74c3c; }
.advancedscroller.skin-qcre .transition-fade .thumbsClip > * {
  opacity: 0;
  visibility: hidden; }
.advancedscroller.skin-qcre .transition-fade .thumbsClip > *.currItem {
  opacity: 1;
  visibility: visible; }
.advancedscroller.skin-qcre .bulletsCon {
  position: absolute;
  width: 100%;
  bottom: 10px;
  margin: 0;
  text-align: center; }
  .advancedscroller.skin-qcre .bulletsCon .bullet {
    width: 20px;
    height: 4px;
    border-radius: 3px;
    background-color: #4d4d4d;
    background-image: none; }
  .advancedscroller.skin-qcre .bulletsCon .bullet.active {
    background-color: #00a99d; }

/* -- END skin-qcre-inset */
.advancedscroller.skin-bubble-inset > .arrowsCon > .arrow-left, .advancedscroller.skin-bubble-inset .arrowsCon > .arrow-right {
  background-image: none;
  left: 50%;
  margin-left: -27px;
  top: auto;
  bottom: 10px;
  margin-top: -15px;
  opacity: 1;
  width: 44px;
  height: 44px; }
.advancedscroller.skin-bubble-inset > .arrowsCon > .arrow-right {
  background-image: none;
  left: 50%;
  margin-left: 27px;
  top: auto;
  bottom: 10px;
  margin-top: -15px; }
.advancedscroller.skin-bubble-inset > .arrowsCon > .arrow-left:hover #Layer_1 path, .advancedscroller.skin-bubble-inset .arrowsCon > .arrow-right:hover path {
  fill: #fff; }

.advancedscroller.skin-giza {
  margin-bottom: 10px;
  padding-bottom: 30px; }
  .advancedscroller.skin-giza > .arrowsCon > .arrow-left, .advancedscroller.skin-giza .arrowsCon > .arrow-right {
    background-image: none;
    left: 50%;
    margin-left: -35px;
    top: auto;
    bottom: 0px;
    margin-top: -15px;
    opacity: 1;
    width: 30px;
    height: 30px; }
  .advancedscroller.skin-giza > .arrowsCon > .arrow-right {
    background-image: none;
    left: 50%;
    margin-left: 5px;
    top: auto;
    bottom: 0px;
    margin-top: -15px; }
  .advancedscroller.skin-giza > .arrowsCon > .arrow-left circle, .advancedscroller.skin-giza .arrowsCon > .arrow-right circle {
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    -moz-transition-property: all;
    -moz-transition-duration: 0.3s;
    -moz-transition-timing-function: ease-out;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    -o-transition-property: all;
    -o-transition-duration: 0.3s;
    -o-transition-timing-function: ease-out;
    -ms-transition-property: all;
    -ms-transition-duration: 0.3s;
    -ms-transition-timing-function: ease-out; }
  .advancedscroller.skin-giza > .arrowsCon > .arrow-left:hover #Layer_2 circle, .advancedscroller.skin-giza .arrowsCon > .arrow-right:hover #Layer_2 circle {
    fill: #555555; }
  .advancedscroller.skin-giza > .arrowsCon > .arrow-left:hover #Layer_4 circle, .advancedscroller.skin-giza .arrowsCon > .arrow-right:hover #Layer_4 circle {
    stroke: #555555; }

.advancedscroller.skin-giza-bullets .bulletsCon {
  margin-top: 15px;
  text-align: center;
  height: auto; }
  .advancedscroller.skin-giza-bullets .bulletsCon span.bullet {
    width: 12px;
    height: 12px;
    background-image: none;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid #d1b97e;
    margin-right: 4px; }
  .advancedscroller.skin-giza-bullets .bulletsCon > span.bullet:last-child {
    margin-right: 0; }
  .advancedscroller.skin-giza-bullets .bulletsCon span.bullet.active {
    background-color: #d1b97e; }

.advancedscroller.skin-nonav .arrowsCon {
  display: none; }

.advancedscroller.skin-whitefish .arrowsCon {
  display: none; }
.advancedscroller.skin-whitefish .bulletsCon {
  position: absolute;
  width: 100%;
  bottom: 10px;
  margin: 0;
  text-align: center;
  height: auto;
  line-height: 1; }
  .advancedscroller.skin-whitefish .bulletsCon span.bullet {
    width: 8px;
    height: 8px;
    background-image: none;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid #fff;
    margin-right: 4px; }
  .advancedscroller.skin-whitefish .bulletsCon > span.bullet:last-child {
    margin-right: 0; }
  .advancedscroller.skin-whitefish .bulletsCon span.bullet.active {
    background-color: #ffffff; }

.advancedscroller.skin-whitefish.is-thicker .bulletsCon {
  bottom: 28px; }
  .advancedscroller.skin-whitefish.is-thicker .bulletsCon span.bullet {
    width: 10px;
    height: 10px;
    background-image: none;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #fff;
    margin-right: 4px; }
  .advancedscroller.skin-whitefish.is-thicker .bulletsCon > span.bullet:last-child {
    margin-right: 0; }
  .advancedscroller.skin-whitefish.is-thicker .bulletsCon span.bullet.active {
    background-color: #ffffff; }

.dzsas-second-con {
  position: relative;
  overflow: hidden;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 0; }
  .dzsas-second-con .dzsas-second-con--clip {
    position: relative;
    transition-property: left;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    -moz-transition-property: left;
    -moz-transition-duration: 0.3s;
    -moz-transition-timing-function: ease-out;
    -webkit-transition-property: left;
    -webkit-transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    -o-transition-property: left;
    -o-transition-duration: 0.3s;
    -o-transition-timing-function: ease-out;
    -ms-transition-property: left;
    -ms-transition-duration: 0.3s;
    -ms-transition-timing-function: ease-out; }
    .dzsas-second-con .dzsas-second-con--clip > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      visibility: hidden;
      transition-property: opacity, visibility;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
      -moz-transition-property: opacity, visibility;
      -moz-transition-duration: 0.3s;
      -moz-transition-timing-function: ease-out;
      -webkit-transition-property: opacity, visibility;
      -webkit-transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      -o-transition-property: opacity, visibility;
      -o-transition-duration: 0.3s;
      -o-transition-timing-function: ease-out;
      -ms-transition-property: opacity, visibility;
      -ms-transition-duration: 0.3s;
      -ms-transition-timing-function: ease-out; }
    .dzsas-second-con .dzsas-second-con--clip > *.active {
      opacity: 1;
      visibility: visible; }

/* ====== caption styling mami

*/
.advancedscroller .caption {
  position: absolute; }
.advancedscroller .caption.skin-underlay {
  bottom: -70%;
  left: 0;
  width: 100%;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
  padding: 10px 10px;
  background: rgba(230, 230, 230, 0.9);
  color: #111;
  transition-property: bottom;
  transition-duration: 1.2s;
  transition-timing-function: ease-out;
  -moz-transition-property: bottom;
  -moz-transition-duration: 1.2s;
  -moz-transition-timing-function: ease-out;
  -webkit-transition-property: bottom;
  -webkit-transition-duration: 1.2s;
  -webkit-transition-timing-function: ease-out;
  -o-transition-property: bottom;
  -o-transition-duration: 1.2s;
  -o-transition-timing-function: ease-out;
  -ms-transition-property: bottom;
  -ms-transition-duration: 1.2s;
  -ms-transition-timing-function: ease-out; }
.advancedscroller .item.currItem .caption {
  bottom: 0; }
.advancedscroller .imagediv {
  display: block;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

/* ====== misc

*/
.giza-circle {
  border-radius: 50%;
  border: 4px solid #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 0 0 1px #e2e2e2;
  transition-property: background-color, color;
  transition-duration: 0.3s;
  transition-timing-function: ease-out-quart;
  -moz-transition-property: background-color, color;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: ease-out-quart;
  -webkit-transition-property: background-color, color;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out-quart;
  -o-transition-property: background-color, color;
  -o-transition-duration: 0.3s;
  -o-transition-timing-function: ease-out-quart;
  -ms-transition-property: background-color, color;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out-quart; }

.social-meta-con h4 {
  color: inherit;
  float: left;
  margin-top: 0;
  margin-bottom: 0; }
.social-meta-con .social-circle-con {
  float: right;
  margin-top: -4px; }
.social-meta-con h3 {
  font-size: 30px;
  color: inherit;
  font-weight: 300;
  margin-top: 10px; }

.nicetext {
  color: #FFFFFF;
  font-style: italic;
  font-size: 17px;
  font-family: "Lato",arial, serif;
  line-height: 1.7; }

.social-circle-con {
  position: relative;
  display: inline-block;
  margin-right: 2px;
  width: 24px;
  height: 24px;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1); }
  .social-circle-con > i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    color: #FFFFFF; }

.circle-con {
  border: 2px solid #FFFFFF;
  border-radius: 100%;
  width: 110px;
  height: 110px;
  position: relative; }
  .circle-con img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%); }

#parallax {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 850px;
  overflow: hidden;
  z-index: 2;
  margin: 0; }

#parallax li {
  position: absolute;
  top: 0;
  left: 0; }

#parallax li.prl1 {
  background: url(bokeh/b1.png);
  width: 2200px;
  height: 800px; }

#parallax li.prl2 {
  background: url(bokeh/b2.png);
  width: 2050px;
  height: 600px; }

#parallax li.prl3 {
  background: url(bokeh/b3.png);
  width: 2500px;
  height: 700px; }

#parallax li.prl4 {
  background: url(bokeh/b4.png);
  width: 1900px;
  height: 700px; }

/*# sourceMappingURL=plugin.css.map */
