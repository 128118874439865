@font-face {
  font-family: 'hs-icons';
  src:
    url('fonts/hs-icons.ttf?xa77py') format('truetype'),
    url('fonts/hs-icons.woff?xa77py') format('woff'),
    url('fonts/hs-icons.svg?xa77py#hs-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.hs-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'hs-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hs-icon-arrow-bottom:before {
  content: "\e900";
}
.hs-icon-arrow-left:before {
  content: "\e901";
}
.hs-icon-arrow-right:before {
  content: "\e902";
}
.hs-icon-arrow-top:before {
  content: "\e903";
}
.hs-icon-close:before {
  content: "\e904";
}
.hs-icon-hamburger-2:before {
  content: "\e905";
}
.hs-icon-hamburger:before {
  content: "\e906";
}
.hs-icon-lula-kebab-h:before {
  content: "\e907";
}
.hs-icon-lula-kebab-v:before {
  content: "\e908";
}
.hs-icon-magnifier:before {
  content: "\e909";
}
.hs-icon-music:before {
  content: "\e90a";
}
.hs-icon-photo:before {
  content: "\e90b";
}
.hs-icon-play:before {
  content: "\e90c";
}
.hs-icon-plus:before {
  content: "\e90d";
}
.hs-icon-unzoom:before {
  content: "\e90e";
}
.hs-icon-video:before {
  content: "\e90f";
}
.hs-icon-zoom:before {
  content: "\e910";
}

