.hs-bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
}

.hs-youtube,
.hs-vimeo {
  display: none;
}

.hs-html5 {
  /* width: auto; */
  width: 100%;
  max-width: none;
  height: auto;
  min-height: 100%;
}

.hs-video-preview {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: none;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

[data-hs-bgv-id],
[data-hs-bgv-path] {
  overflow: hidden;
}

@media (min-width: 768px) {
  .hs-youtube,
  .hs-vimeo {
    display: block;
    width: 100%;
    height: 130%;
  }

  .hs-youtube iframe,
  .hs-youtube embed,
  .hs-vimeo iframe,
  .hs-vimeo embed {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .hs-video-preview {
    display: block;
  }
}
